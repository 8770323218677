import React from "react";
import "./Banner.css";
import Button from "../Button";
import illustration from "../../assets/feedback-illustration.svg";
import { useNavigate } from "react-router-dom";

function Banner() {
  const navigate = useNavigate();
  return (
    <div className="banner">
      <div className="wrapped banner-wrapper">
        <div className="banner-left side">
          <div className="title-wrapper">
            <h1>
              Give User Feedback <br></br>to AI Ethics Guidelines
            </h1>
          </div>
          <Button
            text={"Get Started"}
            onClick={() => {
              navigate("/auth");
            }}
          />
        </div>
        <div className="banner-right side">
          <img src={illustration} alt="feedback illustration" />
        </div>
      </div>
    </div>
  );
}
export default Banner;
