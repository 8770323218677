import React, { useRef } from "react";
import { useState } from "react";
import Button from "../../components/Button";
import "./Auth.css";
import { signUp, Login } from "../../firebase";
import { useNavigate, Navigate } from "react-router-dom";

function Auth(props) {
  const [userRegistered, setUserRegistered] = useState();
  const [hideButton, setHideButton] = useState(false);
  const [error, setError] = useState();
  const [consentError, setConsentError] = useState();
  const navigate = useNavigate();

  const emailRef = useRef();
  const passwordRef = useRef();
  const consentRef = useRef();

  async function handleSignup() {
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    const consent = consentRef.current.checked;

    setHideButton(true);
    if (consent) {
      try {
        await signUp(email, password);
        navigate("/profile/auth");
      } catch (error) {
        setError(error.code);
      }
    } else {
      setConsentError("You need to agree to the consent statement to proceed.");
    }

    setHideButton(false);
  }

  async function handleLogin() {
    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    setHideButton(true);
    try {
      await Login(email, password);
      navigate("/profile/auth");
    } catch (error) {
      setError(error.code);
    }
    setHideButton(false);
  }

  return props.user ? (
    <Navigate to="/profile/auth" />
  ) : (
    <div className="signin">
      <div className="signin-wrapper wrapped">
        <div className="page-header">
          <h4>Access Feedback and Insights with your email</h4>
        </div>
        <div className="form-wrapper">
          {userRegistered ? (
            <>
              <form>
                {error ? <span className="error">{error}</span> : ""}
                <div className="form-control">
                  <label>Email *</label>
                  <input
                    ref={emailRef}
                    type="email"
                    placeholder="youremail@example.com"
                  />
                </div>
                <div className="form-control">
                  <label>Create Password *</label>
                  <input
                    ref={passwordRef}
                    type="password"
                    placeholder="********"
                  />
                </div>
                <div className="consent-box">
                  {consentError ? (
                    <span className="error">{consentError}</span>
                  ) : (
                    ""
                  )}
                  <span>
                    By registering you agree that the feedback data you provide
                    will be stored and used for research purpose, but your
                    personal data will not be made public.
                  </span>
                  <span>
                    <input
                      required="required"
                      ref={consentRef}
                      type="checkbox"
                      name="consent"
                    />{" "}
                    I agree{" "}
                  </span>
                </div>
                <div className="center">
                  <Button
                    hide={hideButton}
                    onClick={() => handleSignup()}
                    text="Register"
                  />
                </div>
              </form>

              <div className="auth_notice">
                <span>Already registered?</span>
                <h4
                  className="pointer-area"
                  onClick={() => setUserRegistered(false)}
                >
                  Login
                </h4>
              </div>
            </>
          ) : (
            <>
              <form>
                {error ? <span className="error">{error}</span> : ""}
                <div className="form-control">
                  <label>Email *</label>
                  <input
                    ref={emailRef}
                    type="email"
                    placeholder="youremail@example.com"
                  />
                </div>
                <div className="form-control">
                  <label>Password *</label>
                  <input
                    ref={passwordRef}
                    type="password"
                    placeholder="********"
                  />
                </div>
                <div className="center">
                  <Button
                    hide={hideButton}
                    onClick={() => handleLogin()}
                    text="Login"
                  />
                </div>
              </form>
              <div className="auth_notice">
                <span>Are you new here? </span>
                <h4
                  className="pointer-area"
                  onClick={() => {
                    setUserRegistered(true);
                  }}
                >
                  Register
                </h4>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Auth;
