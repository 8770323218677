import React, { useState, useEffect } from "react";
import "./Comment.css";
import { useVoteCount } from "../../firebase";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, update } from "firebase/database";

function Comment(props) {
  const [upvote, setUpVote] = useState(false);
  const [downvote, setDownVote] = useState(false);
  const getVoteCount = useVoteCount(props.title, props.data.comment_id);
  let voteCount = useVoteCount(props.title, props.data.comment_id)[2];
  const db = getDatabase();
  const user = getAuth().currentUser;

  useEffect(() => {
    if (getVoteCount) {
      if (voteCount) {
        if (voteCount.upvote) {
          setUpVote(voteCount.upvote.includes(user.uid));
          setDownVote(false);
        } else if (voteCount.downvote) {
          setDownVote(voteCount.downvote.includes(user.uid));
          setUpVote(false);
        } else {
          setDownVote(false);
          setUpVote(false);
        }
      }
    }
  }, [getVoteCount, props.data.comment_id]);

  const toggleVote = (choice) => {
    if (choice === "up") {
      if (upvote) {
        setUpVote(false);
      } else {
        if (downvote) {
          setDownVote(false);
        }
        setUpVote(true);
      }
    }

    if (choice === "down") {
      if (downvote) {
        setDownVote(false);
      } else {
        if (upvote) {
          setUpVote(false);
        }
        setDownVote(true);
      }
    }

    updateVote(choice);
  };

  const updateVote = (choice) => {
    const updates = {};
    let newVoteCount;

    if (choice) {
      if (choice === "up") {
        if (getVoteCount) {
          if (voteCount) {
            if (voteCount.upvote) {
              if (voteCount.upvote.includes(user.uid)) {
                newVoteCount = {
                  ...voteCount,
                  upvote: voteCount.upvote.filter((e) => e !== user.uid),
                };
              } else {
                if (
                  voteCount.downvote &&
                  voteCount.downvote.includes(user.uid)
                ) {
                  newVoteCount = {
                    downvote: voteCount.downvote.filter((e) => e !== user.uid),
                    upvote: [...voteCount.upvote, user.uid],
                  };
                } else {
                  newVoteCount = {
                    ...voteCount,
                    upvote: [...voteCount.upvote, user.uid],
                  };
                }
              }
            } else {
              if (voteCount.downvote && voteCount.downvote.includes(user.uid)) {
                newVoteCount = {
                  downvote: voteCount.downvote.filter((e) => e !== user.uid),
                  upvote: [user.uid],
                };
              } else {
                newVoteCount = {
                  ...voteCount,
                  upvote: [user.uid],
                };
              }
            }
          } else {
            newVoteCount = {
              upvote: [user.uid],
            };
          }
        }
      }

      if (choice === "down") {
        if (getVoteCount) {
          if (voteCount) {
            if (voteCount.downvote) {
              if (voteCount.downvote.includes(user.uid)) {
                newVoteCount = {
                  ...voteCount,
                  downvote: voteCount.downvote.filter((e) => e !== user.uid),
                };
              } else {
                if (voteCount.upvote && voteCount.upvote.includes(user.uid)) {
                  newVoteCount = {
                    downvote: [...voteCount.downvote, user.uid],
                    upvote: voteCount.upvote.filter((e) => e !== user.uid),
                  };
                } else {
                  newVoteCount = {
                    ...voteCount,
                    downvote: [...voteCount.downvote, user.uid],
                  };
                }
              }
            } else {
              if (voteCount.upvote && voteCount.upvote.includes(user.uid)) {
                newVoteCount = {
                  downvote: [user.uid],
                  upvote: voteCount.upvote.filter((e) => e !== user.uid),
                };
              } else {
                newVoteCount = {
                  ...voteCount,
                  downvote: [user.uid],
                };
              }
            }
          } else {
            newVoteCount = {
              downvote: [user.uid],
            };
          }
        }
      }

      updates[
        "/comments/" + props.title + "/" + props.data.comment_id + "/votes"
      ] = newVoteCount;

      return update(ref(db), updates);
    }
  };

  return (
    <div className="single-comment">
      <div className="comment-avatar">O</div>
      <div className="comment-body">
        <p>{props.data.comment}</p>
        <div className="comment-meta">
          <div className="comment-vote up" onClick={() => toggleVote("up")}>
            <img
              alt="vote"
              className="vote vote-clicked"
              src={require("./../../assets/upvote_" +
                (upvote ? "clicked" : "unclicked") +
                ".png")}
            />
            <span className={"bold " + (upvote ? "clicked" : "")}>
              {voteCount && voteCount.upvote ? voteCount.upvote.length : 0}
            </span>
            <span>Agree</span>
          </div>
          <div className="comment-vote down" onClick={() => toggleVote("down")}>
            <img
              alt="vote"
              className="vote vote-clicked"
              src={require("./../../assets/downvote_" +
                (downvote ? "clicked" : "unclicked") +
                ".png")}
            />
            <span className={"bold " + (downvote ? "clicked" : "")}>
              {voteCount && voteCount.downvote ? voteCount.downvote.length : 0}
            </span>
            <span>Disagree</span>
          </div>
          <div className="comment-date">{/* <span>25th Jan 2022</span> */}</div>
        </div>
      </div>
    </div>
  );
}
export default Comment;
