import React, { useState } from "react";
import "./Insight.css";
import {
  usePrinciples,
  useFeedbacks,
  useParticipantCount,
} from "../../firebase";
import Loading from "../../components/Loading";
import InsightBox from "./InsightBox";

function Insight() {
  const principles = usePrinciples();
  const feedbacks = useFeedbacks();
  const particpantsCount = useParticipantCount();
  const [selectedPrinciple, setSelectedPrinciple] = useState(null);
  let togglePrinciple = (id) => {
    selectedPrinciple === id
      ? setSelectedPrinciple(null)
      : setSelectedPrinciple(id);
  };

  return (
    <div className="insight-panel">
      <div className="insight-panel-wrapper wrapped">
        <div className="page-header">
          <h3>
            <b className="green">
              {particpantsCount ? Object.keys(particpantsCount).length : "0"}
            </b>{" "}
            total participants have used this tool. Below are insights from
            their feedback.
          </h3>
        </div>
        {!principles ? (
          <Loading label="insights" />
        ) : (
          <div className="insight-panel-wrapper">
            {principles.map((data, index) => {
              return (
                <InsightBox
                  index={index}
                  data={data}
                  principles={principles}
                  selectedPrinciple={selectedPrinciple}
                  feedbacks={feedbacks}
                  togglePrinciple={togglePrinciple}
                  className={
                    "insight-box " +
                    (selectedPrinciple === index ? "expanded" : "collapsed")
                  }
                  open={selectedPrinciple === index ? true : false}
                  key={index}
                  onClick={
                    selectedPrinciple !== index
                      ? () => togglePrinciple(index)
                      : () => {}
                  }
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default Insight;
