import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./styles/App.css";
import Landing from "./pages/Landing";
import Loading from "./components/Loading";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Auth from "./pages/Auth";
import Feedback from "./pages/Feedback";
import Survey from "./pages/Feedback/Survey";
import Insight from "./pages/Insight";
import Profile from "./pages/Profile";
import { useAuth, useAuthHeader } from "./firebase";

function App() {
  const currentUser = useAuth();
  const header = useAuthHeader();

  return (
    <div className="App">
      <div className="body-wrapper">
        <BrowserRouter>
          <Header status={header} />
          <Routes>
            <Route>
              <Route path="/" exact element={<Landing user={currentUser} />} />
              <Route path="/auth" element={<Auth user={currentUser} />} />
              {currentUser ? (
                <>
                  <Route exact path="/profile" element={<Profile />} />
                  <Route exact path="/profile/:pageRef" element={<Profile />} />
                  <Route exact path="/survey" element={<Feedback />} />
                  <Route
                    exact
                    path="/survey/:principleId"
                    element={<Survey />}
                  />
                  <Route exact path="/insight" element={<Insight />} />
                </>
              ) : (
                <Route path="*" element={<Loading label="page" />} />
              )}
            </Route>
            <Route path="*" element={<Landing user={currentUser} />} />
          </Routes>
        </BrowserRouter>

        <Footer />
      </div>
    </div>
  );
}

export default App;
