import { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
} from "firebase/auth";
import {
  getDatabase,
  ref,
  onValue,
  query,
  equalTo,
  orderByChild,
} from "firebase/database";

// Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyD18lWD3JENVcJn8b4lK0rk7o-_VMR4PlE",
  authDomain: "aieg-feedback-tool.firebaseapp.com",
  databaseURL:
    "https://aieg-feedback-tool-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "aieg-feedback-tool",
  storageBucket: "aieg-feedback-tool.appspot.com",
  messagingSenderId: "795692007526",
  appId: "1:795692007526:web:1bf2a2544833167feb6da7",
  measurementId: "G-DTBX5S9SFV",
};

// Initialize Firebase
initializeApp(firebaseConfig);
const auth = getAuth();

export function signUp(email, password) {
  return createUserWithEmailAndPassword(auth, email, password);
}

export function Login(email, password) {
  return signInWithEmailAndPassword(auth, email, password);
}

export function logout() {
  return signOut(auth);
}

// Custom Hook

export function useAuth() {
  const [currentUser, setCurrentUser] = useState();
  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return unsub;
  }, []);

  return currentUser;
}

export function useAuthHeader(status) {
  const [header, setHeader] = useState();
  useEffect(() => {
    status ? setHeader("auth") : setHeader("");
  }, []);
  return header;
}

export function usePrinciples() {
  const db = getDatabase();
  const [data, setData] = useState();

  useEffect(() => {
    const dbRef = ref(db, "principles");
    onValue(dbRef, (snapshot) => {
      setData(snapshot.val());
    });
  }, []);
  return data;
}

export function useProfileData() {
  const db = getDatabase();
  const auth = getAuth();
  const [data, setData] = useState();

  useEffect(() => {
    const dbRef = ref(db, "user_data/" + auth.currentUser.uid);
    onValue(dbRef, (snapshot) => {
      setData(snapshot.val());
    });
  }, []);
  return data;
}

export function useParticipantCount() {
  const db = getDatabase();
  const [data, setData] = useState();

  useEffect(() => {
    const dbRef = ref(db, "user_data/");
    onValue(dbRef, (snapshot) => {
      setData(snapshot.val());
    });
  }, []);
  return data;
}

export function useFeedbacks() {
  const db = getDatabase();
  const [data, setData] = useState();

  useEffect(() => {
    const dbRef = ref(db, "feedbacks");
    onValue(dbRef, (snapshot) => {
      setData(snapshot.val());
    });
  }, []);
  return data;
}

export function useFeedback(title) {
  const db = getDatabase();
  const [data, setData] = useState();

  useEffect(() => {
    const dbRef = ref(db, "feedbacks/" + title);
    onValue(dbRef, (snapshot) => {
      setData(snapshot.val());
    });
  }, []);
  return data;
}

export function useUserFeedback(title) {
  const db = getDatabase();
  const user = getAuth().currentUser;
  const [data, setData] = useState();

  useEffect(() => {
    const dbRef = ref(db, "feedbacks/" + title);
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        const childKey = childSnapshot.key;
        const childData = childSnapshot.val();
        if (childKey === user.uid) {
          setData(childData);
        }
      });
    });
  }, []);
  return data;
}

export function useUserFeedbackCount(title, reload) {
  const db = getDatabase();
  const [data, setData] = useState();

  let score = ["0", "50", "100"];
  let scoreArray = [];
  let totalScore = {
    "q1-answer": 0,
    "q2-answer": 0,
    "q3-answer": 0,
    "q4-answer": 0,
    "q5-answer": 0,
    "q6-answer": 0,
  };

  useEffect(() => {
    const dbRef = ref(db, "feedbacks/" + title);

    score.forEach((scoreData) => {
      let chartFullData = {
        "q1-answer": 0,
        "q2-answer": 0,
        "q3-answer": 0,
        "q4-answer": 0,
        "q5-answer": 0,
        "q6-answer": 0,
      };

      Object.keys(chartFullData).forEach((key) => {
        const recentPostsRef = query(
          dbRef,
          orderByChild(key),
          equalTo(scoreData)
        );

        onValue(recentPostsRef, (snapshotz) => {
          chartFullData[key] = chartFullData[key] + snapshotz.size;
          totalScore[key] = totalScore[key] + snapshotz.size;
        });
      });

      let dataArray = Object.keys(chartFullData).map(function (key) {
        return chartFullData[key];
      });

      scoreArray = [...scoreArray, dataArray];
    });

    let totalScoreArray = Object.keys(totalScore).map(function (key) {
      return totalScore[key];
    });

    scoreArray.forEach((scoreData, keyx) => {
      scoreData.forEach((data, keyz) => {
        return (scoreArray[keyx][keyz] = Math.round(
          (scoreArray[keyx][keyz] / totalScoreArray[keyz]) * 100
        ));
      });
    });
    setData(scoreArray);
  }, []);

  return data;
}

export function useReload(title) {
  const db = getDatabase();
  const [data, setData] = useState([]);

  useEffect(() => {
    const dbRef = ref(db, "feedbacks/" + title);
    const recentPostsRef = query(dbRef, orderByChild(title), equalTo(title));

    onValue(recentPostsRef, (snapshot) => {
      if (snapshot.val()) {
        setData(Object.values(snapshot.val()));
      }
    });
  }, []);
  return data;
}

export function useComments(title) {
  const db = getDatabase();
  const [data, setData] = useState([]);

  useEffect(() => {
    const dbRef = ref(db, "comments/" + title);
    onValue(dbRef, (snapshot) => {
      if (snapshot.val()) {
        setData(Object.values(snapshot.val()));
      }
    });
  }, []);
  return data;
}

export function useVoteCount(title, user) {
  const db = getDatabase();
  const [data, setData] = useState([]);

  useEffect(() => {
    const dbRef = ref(db, "comments/" + title + "/" + user);
    onValue(dbRef, (snapshot) => {
      if (snapshot.val()) {
        setData(Object.values(snapshot.val()));
      }
    });
  }, []);
  return data;
}

export function getPrinciple(principle_id) {
  const db = getDatabase();
  const [data, setData] = useState();

  useEffect(() => {
    const dbRef = ref(db, "principles/");
    onValue(dbRef, (snapshot) => {
      setData(snapshot.val()[principle_id]);
    });
  }, []);
  return data;
}
