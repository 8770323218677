import React, { useState, useEffect } from "react";
import Button from "../../components/Button";
import { useUserFeedback } from "../../firebase";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, update } from "firebase/database";

function FeedbackForm(props) {
  const [answers, setAnswers] = useState();
  const [notice, setNotice] = useState();
  const currentUser = getAuth().currentUser;
  const feedback = useUserFeedback(props.principle.title);

  const db = getDatabase();
  const user = getAuth().currentUser;

  useEffect(() => {
    setAnswers(feedback);
  }, [feedback]);

  const handleAnswerChange = (e) => {
    setAnswers({ ...answers, [e.target.name]: e.target.value });
  };

  const handleSubmitResponse = () => {
    setNotice("Feedback Updated");
    updateFeedback(user, db);

    setTimeout(() => {
      setNotice(null);
    }, 5000);
  };

  const updateFeedback = (user, db) => {
    const updates = {};

    let response = {
      title: props.principle.title,
      user_email: currentUser.email,
      user_id: currentUser.uid,
      "q1-answer": answers["q1-answer"] || "50",
      "q2-answer": answers["q2-answer"] || "50",
      "q3-answer": answers["q3-answer"] || "50",
      "q4-answer": answers["q4-answer"] || "50",
      "q5-answer": answers["q5-answer"] || "50",
      "q6-answer": answers["q6-answer"] || "50",
      ...answers,
    };

    updates["/feedbacks/" + props.principle.title + "/" + currentUser.uid] =
      response;

    if (answers.comment) {
      let comment = {
        comment: answers.comment,
        comment_id: currentUser.uid,
      };

      updates["/comments/" + props.principle.title + "/" + currentUser.uid] =
        comment;
    }

    return update(ref(db), updates);
  };

  return (
    <div className="feedback-form">
      <form>
        {props.principle.questions.map((data, index) => {
          return (
            <div className="form-control" key={index}>
              <div className=" survey-slider">
                <span>{data}</span>
                <input
                  type="range"
                  className="slider"
                  step="50"
                  min="0"
                  max="100"
                  name={"q" + (index + 1) + "-answer"}
                  onChange={handleAnswerChange}
                  value={answers ? answers["q" + (index + 1) + "-answer"] : 50}
                />
                <ul>
                  <li>Disagree</li>
                  <li>Neutral</li>
                  <li>Agree</li>
                </ul>
              </div>
            </div>
          );
        })}

        <div className="comment-reply">
          <h4>
            In addition to the feedback above, kindly express your general view
            on {props.principle.title} (optional).
          </h4>
          <textarea
            placeholder="Add optional comment..."
            rows="4"
            name={"comment"}
            value={answers ? answers["comment"] : ""}
            onChange={handleAnswerChange}
          />
        </div>
        <div className="center">
          {notice ? <span className="notice">{notice}</span> : ""}
          <Button onClick={handleSubmitResponse} text="Update Response" />
        </div>
      </form>
    </div>
  );
}

export default FeedbackForm;
