import React, { useState, useEffect } from "react";
import InsightChart from "../../components/InsightChart";
import "./Insight.css";
import { useComments, useUserFeedbackCount } from "../../firebase";
import Comment from "../../components/Comment";

function InsightBox(props) {
  const [comments, setComments] = useState();
  const [open, setOpen] = useState();
  const getComments = useComments(props.data.title);
  const getFeedback = useUserFeedbackCount(props.title);

  useEffect(() => {
    setComments(getComments);
  }, [getComments]);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open, getFeedback]);

  return (
    <div className={props.className}>
      <div
        className="insight-box-header"
        onClick={
          props.Principle !== props.index
            ? () => props.togglePrinciple(props.index)
            : () => {}
        }
      >
        <h3>{props.data.title}</h3>
        <img
          alt="toggle view"
          className="close principle-toggle"
          src={require("./../../assets/" +
            (props.selectedPrinciple === props.index ? "close" : "open") +
            ".svg")}
          onClick={() => props.togglePrinciple(props.index)}
        />
      </div>
      <div className="top-box">
        <div className="principle-text part left">
          <p>{props.data.intro}</p>
        </div>
        <div className=" principle-questions part right">
          <ul>
            {props.principles[props.index].questions.map((data, indexi) => {
              return (
                <li key={indexi}>
                  <b>Q{indexi + 1}:</b>
                  <p>{data}</p>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="chart-box">
        {open ? <InsightChart type="bar" title={props.data.title} /> : ""}
      </div>
      <div className="comment-box-wrapper">
        <h4>Comments</h4>
        <div className="comment-box">
          {!comments || comments.length === 0 ? (
            <p>There is no comment yet</p>
          ) : (
            <ul>
              {comments.map((data, index) => {
                return (
                  <li key={index}>
                    <Comment data={data} title={props.data.title} />
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default InsightBox;
