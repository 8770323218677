import React, { useState } from "react";
import Banner from "../../components/Banner";
import "./Landing.css";
import privacy from "../../assets/privacy.svg";
import easy from "../../assets/easy.svg";
import optional from "../../assets/optional.svg";
import secure from "../../assets/secure.svg";
import Button from "../../components/Button";
import Popup from "../../components/Popup";

function Landing() {
  const [shareStatus, setShareStatus] = useState(false);
  const handleShareButton = () => {
    setShareStatus(true);
  };
  const closePopUp = () => {
    document.body.classList.remove("no-scroll");
    setShareStatus(false);
  };

  return (
    <>
      {shareStatus ? (
        <Popup status={shareStatus} onClick={closePopUp} />
      ) : (
        <></>
      )}

      <Banner />
      <div className="intro row dark full">
        <div className="intro-wrapper wrapped">
          <div className="col half">
            <h3>
              This is a tool for all kinds of users to briefly preview certain
              AI Ethics Guidelines, as well as to offer feedback to them. The
              feedback could stem from personal experience or perception of the
              principles and criteria.
            </h3>
          </div>
          <div className="col half">
            <h3>
              This is in a bid to foster increased dialogue around the Ethical
              design, implementation and use of Artificial Intelligence, as it
              is a prevailing Human advancement requiring such level of
              attention, especially among end-users.
            </h3>
          </div>
        </div>
      </div>
      <div className="pointers">
        <div className="pointers-wrapper wrapped">
          <div className="pointer">
            <img src={privacy} alt="" />
            <h4>
              Anonymously give feedback without exposing personal data for
              unknown or unauthorised use.
            </h4>
          </div>
          <div className="pointer">
            <img src={easy} alt="" />
            <h4>
              Measures have been put in place to make the platform easy to use
              and efficiently user-friendly.
            </h4>
          </div>
          <div className="pointer">
            <img src={optional} alt="" />
            <h4>
              The feedback questions are optional and as such users can choose
              what to answer without compulsion.
            </h4>
          </div>
          <div className="pointer">
            <img src={secure} alt="" />
            <h4>
              Top level security technology have been deployed to ensure secure
              use of the platform against threats.
            </h4>
          </div>
        </div>
      </div>
      <div className="cta">
        <div className="cta-wrapper wrapped">
          <h3>
            Tell others about this to encourage end-user participation in the
            formulation and maintenance of the ethics guidelines of AI
            Technologies.
          </h3>
          <Button onClick={handleShareButton} text="Copy & Share" dark />
        </div>
      </div>
    </>
  );
}

export default Landing;
