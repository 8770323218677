import React, { useState, useEffect } from "react";
import "./Popup.css";

function Popup(props) {
  const [showPopUp, setShowPopUp] = useState();

  useEffect(() => {
    setShowPopUp(props.status);
    document.body.classList.add("no-scroll");
  }, [props.status]);

  return (
    <>
      {showPopUp ? (
        <div className="popup" onClick={props.onClick}>
          <div className="popup-box">
            <span>Click to copy the text below and share with someone.</span>
            <br></br>
            <code
              onClick={() =>
                navigator.clipboard.writeText(
                  "Check this platform for giving feedback to AI Ethics Guidelines.  \nhttps://aieg-feedback-tool.web.app/"
                )
              }
            >
              Check this platform for giving feedback to AI Ethics Guidelines.
              <br></br>
              https://aiegfeedback.online/
            </code>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
export default Popup;
