import React from "react";
import { useParams } from "react-router-dom";
import FeedbackForm from "./FeedbackForm";
import Loading from "../../components/Loading";
import { getPrinciple } from "../../firebase";

function Survey() {
  const { principleId } = useParams();
  const principle = getPrinciple(principleId);

  return (
    <div className="feedback-panel">
      {!principle ? (
        <Loading label="survey data" />
      ) : (
        <div className="feedback-wrapper wrapped">
          <div className="page-header">
            <h1>{principle.title}</h1>
          </div>
          <div className="cards-wrapper survey">
            {principle.content.map((data, index) => {
              return (
                <div className="card" key={index}>
                  <h3>{data.name}</h3>
                  {data.article.map((line, index) => {
                    return <p key={index}>{line}</p>;
                  })}

                  {index === 0 ? (
                    <>
                      <p>Click the links below for more indepth study:</p>{" "}
                      {principle.links.map((link, indexl) => {
                        return (
                          <a
                            className="card-link"
                            key={indexl}
                            rel="noopener noreferrer"
                            href={link.url}
                            target="_blank"
                          >
                            {link.label}
                          </a>
                        );
                      })}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
          </div>
          <div className="page-header">
            <h4>With the information above, kindly express your feedback.</h4>
          </div>
          <FeedbackForm tipicID={principleId} principle={principle} />
        </div>
      )}
    </div>
  );
}

export default Survey;
