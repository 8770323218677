import React from "react";
import "./Loading.css";
import { ReactComponent as LoadingIcon } from "../../assets/loading.svg";

function Loading(props) {
  return (
    <div className="loading">
      <span>loading {props.label}... </span>
      <LoadingIcon />
    </div>
  );
}

export default Loading;
