import React, { useEffect, useState } from "react";
import { useNavigate, Navigate, useParams } from "react-router-dom";
import Button from "../../components/Button";
import "./Profile.css";
import Loading from "../../components/Loading";
import { getAuth } from "firebase/auth";
import { useProfileData } from "../../firebase";
import { getDatabase, ref, update } from "firebase/database";

function Profile(props) {
  const { pageRef } = useParams();
  const userData = useProfileData();
  const [inputs, setInputs] = useState();
  const [notice, setNotice] = useState();
  const [profileComplete, setProfileComplete] = useState(false);

  const db = getDatabase();
  const user = getAuth().currentUser;
  const navigate = useNavigate();

  useEffect(() => {
    setInputs(userData);

    if (userData && Object.keys(userData).length > 7) {
      setProfileComplete(true);
    }
  }, [userData]);

  function handleProfileUpdate() {
    addUserRecord(user, db);
    setNotice("User Record Updated");

    setTimeout(() => {
      setNotice(null);
    }, 5000);
  }

  function addUserRecord(user, db) {
    let updates = {};

    const updatedData = {
      ...inputs,
      user_id: user.uid,
      user_email: user.email,
    };

    updates["/user_data/" + user.uid] = updatedData;

    return update(ref(db), updates);
  }

  function handleInputChange(e) {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  }

  return (
    <div className="profile">
      {!userData && !userData === null ? (
        <Loading label="profile" />
      ) : !(profileComplete && pageRef) ? (
        <div className="profile-wrapper wrapped">
          <div className="page-header">
            <h3>
              Complete your profile with useful information to better optimize
              insights data. (Optional)
            </h3>
          </div>
          {userData || userData === null ? (
            <div className="form-wrapper">
              <form>
                {notice ? <span className="notice">{notice}</span> : ""}
                <div className="form-control">
                  <label>What gender do you identify as?</label>
                  <select
                    value={inputs ? inputs.gender : ""}
                    name="gender"
                    onChange={handleInputChange}
                  >
                    <option value=""> -- </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>

                <div className="form-control">
                  <label>What's your age group?</label>
                  <select
                    value={inputs ? inputs.ageGroup : ""}
                    name="ageGroup"
                    onChange={handleInputChange}
                  >
                    <option value=""> -- </option>
                    <option value="18 - 25">18 - 25</option>
                    <option value="26 - 35">26 - 35</option>
                    <option value="36 - 45">36 - 45</option>
                    <option value="46 - 65">46 - 65</option>
                    <option value="Above 65 or retired">
                      Above 65 or retired
                    </option>
                  </select>
                </div>
                <div className="form-control">
                  <label>Which category will you consider yourself?</label>
                  <select
                    value={inputs ? inputs.category : ""}
                    name="category"
                    onChange={handleInputChange}
                  >
                    <option value=""> -- </option>
                    <option value="AI developer">AI developer</option>
                    <option value="AI observer">AI observer</option>
                    <option value="End User">End User</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="form-control">
                  <label>Have you interacted with any kind of AI before?</label>
                  <select
                    value={inputs ? inputs.familiarity : ""}
                    name="familiarity"
                    onChange={handleInputChange}
                  >
                    <option value=""> -- </option>
                    <option value="Yes - I have">Yes - I have</option>
                    <option value="No - I have not">No - I have not</option>
                    <option value="Maybe - I am not sure">
                      Maybe - I am not sure
                    </option>
                  </select>
                </div>
                <div className="form-control">
                  <label>Are you well aware of existing AI guidelines</label>
                  <select
                    value={inputs ? inputs.awareness : ""}
                    name="awareness"
                    onChange={handleInputChange}
                  >
                    <option value=""> -- </option>
                    <option value="Yes I am<">Yes I am</option>
                    <option value="No I am not">No I am not</option>
                    <option value="Slightly - I am not sure what I know">
                      Slightly - I am not sure what I know
                    </option>
                  </select>
                </div>
                <div className="form-control">
                  <label>
                    Do you feel the need to have a say in these guidelines?
                  </label>
                  <select
                    value={inputs ? inputs.necessity : ""}
                    name="necessity"
                    onChange={handleInputChange}
                  >
                    <option value=""> -- </option>
                    <option value="Yes - I do">Yes - I do</option>
                    <option value="No - I do not">No - I do not</option>
                    <option value="Maybe - I am not sure">
                      Maybe - I am not sure
                    </option>
                  </select>
                </div>
                <div className="center">
                  <Button text="Update Profile" onClick={handleProfileUpdate} />
                  <Button
                    text="Skip"
                    light
                    onClick={() => {
                      navigate("/survey");
                    }}
                  />
                </div>
              </form>
            </div>
          ) : (
            <Loading label="profile" />
          )}
        </div>
      ) : (
        <Navigate to="/survey" />
      )}
    </div>
  );
  // }
}

export default Profile;
