import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-wrapper wrapped">
        <h4 className="left">(c) 2022 De Montfort University.</h4>
        {/* <h4 className="right">Built by Joshua Olonade - P2648428.</h4> */}
        <h4 className="right">Built by Joshua Olonade</h4>
      </div>
    </div>
  );
}

export default Footer;
