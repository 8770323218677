import React, { useState, useEffect } from "react";
import "./InsightChart.css";
import Chart from "react-apexcharts";
import { useUserFeedbackCount, useReload } from "../../firebase";

function InsightChart(props) {
  const [data, setData] = useState();
  const [reload, setReload] = useState();
  const getFeedback = useUserFeedbackCount(props.title);
  const getReload = useReload(props.title);

  useEffect(() => {
    setData(getFeedback);
  }, [reload]);

  useEffect(() => {
    setReload(getReload);
  }, [getReload]);

  if (data) {
    let bar_options = {
      series: [
        {
          name: "Disagree",
          data: data[0],
        },
        {
          name: "Neutral",
          data: data[1],
        },
        {
          name: "Agree",
          data: data[2],
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 450,
        },
        colors: ["#E50B0B", "#0075A7", "#00BA34"],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["transparent"],
        },
        xaxis: {
          categories: ["Q1", "Q2", "Q3", "Q4", "Q5", "Q6"],
        },
        yaxis: {
          title: {
            text: "(% of Participants)",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "%";
            },
          },
        },
      },
    };

    let bar_mobile_options = {
      series: [
        {
          name: "Disagree",
          data: data[0],
        },
        {
          name: "Neutral",
          data: data[1],
        },
        {
          name: "Agree",
          data: data[2],
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 700,
        },
        colors: ["#E50B0B", "#0075A7", "#00BA34"],
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: "90%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["transparent"],
        },
        xaxis: {
          categories: ["Q1", "Q2", "Q3", "Q4", "Q5", "Q6"],
        },
        yaxis: {
          title: {
            text: "(% of Participants)",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "%";
            },
          },
        },
      },
    };

    return (
      <>
        {console.log(data)}
        <div className="chart desktop">
          <Chart
            options={bar_options.options}
            series={bar_options.series}
            type="bar"
            height={350}
          />
        </div>
        <div className="chart mobile">
          <Chart
            options={bar_mobile_options.options}
            series={bar_mobile_options.series}
            type="bar"
            height={350}
          />
        </div>
      </>
    );
  } else return <span>There's no chart data</span>;
}
export default InsightChart;
