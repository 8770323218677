import React, { useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import "./Header.css";
import { useAuth, logout } from "../../firebase";

function Header(props) {
  const status = props.status;
  const navigate = useNavigate();
  const currentUser = useAuth();
  const [showFullEmail, setShowFullEmail] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  let header;

  async function handleLogout() {
    try {
      logout();
      navigate("/");
    } catch {
      alert("Logout Error");
    }
  }

  const handleDisplayEmailToggle = () => {
    setShowFullEmail(!showFullEmail);
  };

  const handleMenuToggle = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  if (!currentUser && status !== "auth") {
    header = (
      <>
        <div className="left half col logo center">
          <Link to="/">
            <img src={require("./../../assets/logo-main.png")} alt="logo" />
          </Link>
        </div>
        {/* <div className="right half col">
          <ul>
            <li>
              <Link to="/auth">
                <h4>Get Started</h4>
              </Link>
            </li>
          </ul>
        </div> */}
      </>
    );
  } else if (!currentUser && status === "auth") {
    header = (
      <div className="center half col logo">
        <Link to="/">
          <img src={require("./../../assets/logo-main.png")} alt="logo" />
        </Link>
      </div>
    );
  } else {
    header = (
      <>
        <div className="left half col logo">
          <Link to="/">
            <img src={require("./../../assets/logo-main.png")} alt="logo" />
          </Link>
        </div>
        <div className="right half col">
          <ul className="menu-list">
            <li>
              <h5
                className="avatar"
                onMouseOver={handleDisplayEmailToggle}
                onMouseOut={handleDisplayEmailToggle}
              >
                {showFullEmail
                  ? currentUser.email
                  : currentUser.email.substring(0, 8) + "..."}
              </h5>
            </li>
            <li>
              <Link to="/insight">
                <h4>Insight</h4>
              </Link>
            </li>
            <li>
              <Link to="/survey">
                <h4>Survey</h4>
              </Link>
            </li>
            <li>
              <Link to="/profile">
                <h4>Profile</h4>
              </Link>
            </li>
            <li
              onClick={() => {
                handleLogout();
              }}
            >
              <h4>Logout</h4>
            </li>
          </ul>
          <img
            alt="toggle menu"
            className="hamburger"
            src={require("./../../assets/menu-hamburger.svg")}
            onClick={handleMenuToggle}
          />
        </div>
        <div
          onClick={handleMenuToggle}
          className={showMobileMenu ? "mobile-menu" : "hide"}
        >
          <ul className="mobile-menu-list">
            <li>
              <h5
                className="avatar"
                onMouseOver={handleDisplayEmailToggle}
                onMouseOut={handleDisplayEmailToggle}
              >
                {currentUser.email}
              </h5>
            </li>
            <li>
              <Link to="/insight">
                <h4>Insight</h4>
              </Link>
            </li>
            <li>
              <Link to="/survey">
                <h4>Survey</h4>
              </Link>
            </li>
            <li>
              <Link to="/profile">
                <h4>Profile</h4>
              </Link>
            </li>
            <li
              onClick={() => {
                handleLogout();
              }}
            >
              <h4>Logout</h4>
            </li>
            <li>
              <img
                alt="toggle menu"
                className="hamburger"
                src={require("./../../assets/close-blue.svg")}
                onClick={handleMenuToggle}
              />
            </li>
          </ul>
        </div>
        <Outlet />
      </>
    );
  }

  return (
    <div className="header">
      <div className="header-wrapper wrapped row header-container">
        {header}
      </div>
    </div>
  );
}

export default Header;
