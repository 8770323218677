import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Loading from "../../components/Loading";
import { usePrinciples } from "../../firebase";
import Popup from "../../components/Popup";
import "./Feedback.css";

function Feedback(props) {
  const principles = usePrinciples();

  // const feedbacks = useFeedbacks();
  const navigate = useNavigate();
  // const feedbacks = useFeedbacks();

  const [shareStatus, setShareStatus] = useState(false);
  const handleShareButton = () => {
    setShareStatus(true);
  };
  const closePopUp = () => {
    document.body.classList.remove("no-scroll");
    setShareStatus(false);
  };

  return (
    <>
      {shareStatus ? (
        <Popup status={shareStatus} onClick={closePopUp} />
      ) : (
        <></>
      )}
      <div className="feedback-panel">
        <div className="feedback-wrapper wrapped">
          <div className="page-header">
            <h3>
              Explore different requirements of AI Ethics Guidelines and give
              accurate feedback.
            </h3>
          </div>
          {!principles ? (
            <Loading label="" />
          ) : (
            <div className="cards-wrapper">
              {principles.map((data, index) => {
                return (
                  <div className="card" key={index}>
                    <h3>{data.title}</h3>
                    <p>{data.intro}</p>
                    <div className="card-base">
                      <Button
                        text="Explore"
                        onClick={() => navigate(`/survey/${index}`)}
                      />
                      <div className="counter">
                        <h3>{data.questions.length}</h3>
                        <p>Total</p>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="card full-card dark">
                <h3>
                  Are you finding this useful?<br></br> Invite more people!
                </h3>
                <div className="card-base">
                  <Button onClick={handleShareButton} text="Invite" dark />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Feedback;
