import React from "react";
import "./Button.css";
function Button(props) {
  return (
    <div
      className={
        "button default " +
        (props.dark ? "dark" : props.light ? "light" : "") +
        (props.hide ? "hide" : "")
      }
      onClick={props.onClick}
    >
      <span>{props.text}</span>
    </div>
  );
}
export default Button;
